import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Box, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import './Homepage.css';

// props: { handleWishList, handleCounter, email, setEmail }
function Homepage() {
  const {t} = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box
      as="header"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0 1rem',
        height: '100vh',
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Stack>
          <h5 className="l-heading">
            {t(
              'With a few clicks, you can create the perfect resume for that dream job.'
            )}
          </h5>
          <p className="m-heading">
            {t(
              'Your resume is the first, and sometimes only chance recruiters and hiring managers get to meet you; it helps to make it count.'
            )}
          </p>
        </Stack>
      </Box>
      {/* <div>
        <input
          type="text"
          placeholder="Enter your Email"
          className="input"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        /> */}
      <Link to="/resumeandcoverletterbuilder/login">
        <button
          type="button"
          className="button"
          // onClick={() => {
          //   handleCounter();
          //   handleWishList();
          // }}
        >
          Build my resume
        </button>
      </Link>
    </Box>
  );
}

export default Homepage;
