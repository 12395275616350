import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: {
      'HR tech startup': `HR tech startup with a focus on cruelty-free hiring.`,
      'Leveraging Best': `Leveraging best in-class applicant tools, enterprise hiring
      platforms based in candidate-led design, to realise a world where
      hiring is ethical, transparent, and efficient.`,
      'Browse through': `Browse through our array of best in class applicant tools and enterprise
      hiring software as part of our efforts to make hiring an arduous free
      experience.`,
      'Resume Builder': `Resume Builder`,
      'Our resume builder': `Our resume builder provides templates, tools, and proven tips for
      building winning resumes in diverse fields.`,
      'Resume and Cover Letter Review': `Resume and Cover Letter Review`,
      'Get your resume': `Let's get your resume and/or cover letter reviewed by industry
      experts and improve your job application outcomes.`,
      'Learn more': `Learn more`,
      'Coming soon': `Coming soon`,
      'Job Board': `Job Board`,
      'Create job post': `Create an ethical job post and improve your applicant's
      experience of the hiring process.`,
      'Get timely updates': `Get timely updates and fresh insights`,
      'Delivered inbox': `delivered to your inbox`,
      'Enter your Email': `Enter your Email`,
      'Subscribe to our newsletter': `Subscribe to our newsletter`,
      'Contact us': `Contact us`,
      'Resume builder': `Resume builder`,
      'Helpful Links': `Helpful Links`,
      Home: `Home`,
      Blog: `Blog`,
      Social: `Social`,
      'Login Header': `Resume Builder, Cover Letter builder and so much more`,
      'Join us': `Join us! We're building a safe space for job seekers and a
      community of ethical and conscientious employers.`,
      'Find help': `Find help from our toolkit to make your job applications easier, faster
      and outstanding. From inspiring cover letters to catchy resumes, over
      1000 professionals benefit from our templates and you could too!`,
      'Like Professionals': `You'll find here a community of like-minded
      professionals`,
      Tools: `Our tools have been approved by recruiters across
      various industries`,
      'Including yours': `including yours.`,
      'Recruitment team': `Empower your recruitment team with our educational
      resources`,
      'All for': `All for`,
      Free: `FREE`,
      'Sign in to Rezzie': `Sign in to Rezzie`,
      'Sign in with LinkedIn': `Sign in with LinkedIn`,
      'Sign in with Google': `Sign in with Google`,
      or: `or`,
      'Email Address': `Email Address`,
      'Enter your email address': `Enter your email address`,
      Password: `Password`,
      'Forgot your password?': `Forgot your password?`,
      'Sign up': `Sign up`,
      'Log in': `Log in`,
      'Please wait...': `Please wait...`,
      'Sign up to Rezzie': `Sign up to Rezzie`,
      'First Name': `First Name`,
      'Enter your first name': `Enter your first name`,
      'Last Name': `Last Name`,
      'Enter your last name ': `Enter your last name`,
      'Enter your password': `Enter your password`,
      'I want to receive updates on blog posts': `I want to receive updates on blog posts`,
      'Already have an account?': `Already have an account?`,
      'Sign in': `Sign in`,
      'Create account': `Create account`,
      'You have been registered': `You have been registered`,
      'Rezzie | Sign Up': `Rezzie | Sign Up`,
      'Build a new resume': `Build a new resume`,
      'View/edit resume': ` View/edit your existing resume`,
      'Check out our resume templates': `Check out our resume templates`,
      'Contact Information': `Contact Information`,
      'Fill Contact Information': `Please fill in your preferred contact information`,
      'Your resume has': `Your resume has`,
      stars: `stars`,
      'Fill all sections': `Fill all sections to have all Rezzie stars! We highly recommend you
      do.`,
      'You made it': `You made it!`,
      'Preview all sections': `Please preview all sections to ensure there are no errors.`,
      'Volunteer History': `Volunteer History`,
      Education: `Education`,
      'Work Experience': `Work Experience`,
      Headline: `Headline`,
      'Licenses and Certifications': `Licenses and Certifications`,
      Accomplishments: `Accomplishments`,
      Skills: `Skills`,
      'Proceed to select a template': `Proceed to select a template`,
      Back: `Back`,
      Step: `Step`,
      'Skip This': `Skip This`,
      Country: `Country`,
      'Select Country': `Select Country`,
      'State/City': `State/City`,
      'Select State/City': `'Select State/City'`,
      'LinkedIn URL': `LinkedIn URL`,
      'Portfolio URL': `Portfolio URL`,
      'Next Step': `Next Step`,
      Save: `Save`,
      'Change Template': `Change Template`,
      Download: `Download`,
      Preview: `Preview`,
      'The exciting part': `The exciting part!`,
      'What sort of feel': `What sort of feel are you going for? Pick one of our amazing templates`,
      'Choose Template': `Choose Template`,
      'Download this template': `Download this template`,
      'Change this template': `Change this template`,
      'Cover Letter Builder': `Cover Letter Builder`,
      'Log Out': `Log Out`,
      'Build a new cover letter': `Build a new cover letter`,
      'View/edit cover letter': `View/edit your existing cover letter`,
      'Use our cover letter samples': `Use our cover letter samples`,
      'Tips to help you': `Tips to help you`,
      'Introduction Tip 1.1': `Your introduction should tell the hiring manager who you are, why you
      are applying for the role and a summary of your experience in relation
      to the role you're applying for. Infusing an interesting fact
      about the company makes for a more captivating introduction.`,
      'Introduction Tip 1.2': `E.g. As a strong believer in the innovative prowess of ABC
      Engineering, I am greatly honoured to put forth my application for
      the position of Junior Electrical Engineer at your organization`,
      'Introduction Tip 1.3': `[why you're applying for the role]`,
      'Introduction Tip 1.4': `Since
      obtaining my Bachelor of Engineering degree from the University of
      Saw last June`,
      'Introduction Tip 1.5': `[who you are]`,
      'Introduction Tip 1.6': ` I have set my sights
      on ABC. This is due to my interest in network analysis and
      synthesis, a field which your organization has championed for the
      last 20 years`,
      'Introduction Tip 1.7': `[interesting fact about the company]`,
      'Supporting Evidence Tip 1.1': `Your evidence should show the hiring manager a history of achievements
      relevant to the job that you're applying for. You don't have
      to talk about all the jobs you've done. Simply demonstrate how
      your skills have yielded positive results in your previous roles.`,
      'Supporting Evidence Tip 1.2': `E.g I have mastered communicating with my target audience in a
      manner that creates the connection required to build the brand image
      on social media `,
      'Supporting Evidence Tip 1.3': `[skill 1]`,
      'Supporting Evidence Tip 1.4': `I'm also proficient
      at designing campaign strategies that meet clients' needs`,
      'Supporting Evidence Tip 1.5': `[skill 2]`,
      'Supporting Evidence Tip 1.6': `These skills have helped me increase the
      social media reach of brands like FareD by 312% within 6 months`,
      'Supporting Evidence Tip 1.7': `[significant achievement]`,
      'Summary Tip 1.1': `Be polite in your summary. Restate your interest in the position and
      include a call-to-action i.e. a statement that shows the
      recruiter/hiring manager that you're looking forward to the next
      steps.`,
      'Summary Tip 1.2': `E.g. I look forward to contributing my skills and experiences to the
      XYZ position at ABC `,
      'Summary Tip 1.3': `[expression of interest]`,
      'Summary Tip 1.4': `I
      hope to get the opportunity to speak with you further about how I
      can be an asset to your team`,
      'Summary Tip 1.5': `[call to action]`,
      'Summary Tip 1.6': `Sincerely,`,
      'Summary Tip 1.7': `John`,
      'Have any accomplishments you’d like to add?': `Have any accomplishments you’d like to add?`,
      Publications: `Publications`,
      Patents: `Patents`,
      Courses: `Courses`,
      Projects: `Projects`,
      'Honours & Awards': `Honours & Awards`,
      'Test Scores': `Test Scores`,
      Languages: `Languages`,
      Organisations: `Organisations`,
      'Here we want to hear your education history.': `Here we want to hear your education history.`,
      'Education Tip 1.1': `Outline courses relevant to the job (especially if
        you're a graduate seeking work experience) as well as
        achievements and relevant student activities. Oftentimes,
        recruiters will not find your primary and secondary
        education relevant to the role, Consider omitting them.`,
      'Education #': `Education #`,
      Name: `Name`,
      Remove: `Remove`,
      Degree: `Degree`,
      'Select Degree': `Select Degree`,
      Associate: `Associate`,
      Bachelors: `Bachelor's`,
      Masters: `Masters`,
      'PHD/Doctorate': `  PHD/Doctorate`,
      'Field of Study': `Field of Study`,
      'Date Started': `Date Started`,
      'I currently study here': `I currently study here`,
      'End Date': `End Date`,
      'When did you end at this role?': `When did you end at this role?`,
      Grade: `Grade`,
      'Activities and Societies': `Activities and Societies`,
      'Share any activities and socities you joined or participated in': `Share any activities and socities you joined or participated in`,
      'Add Education': `Add Education`,
      'Education Tip 1.2': `Outline courses relevant to the job (especially if you're a
        graduate seeking work experience) as well as achievements and
        relevant student activities. Oftentimes, recruiters will not find
        your primary and secondary education relevant to the role, Consider
        omitting them.`,
      'Experience Tip 1.1': `Give us all the details on the work experiences you've had
      over time. It'll be nice to tailor this section to the
      role(s) you will be applying to with this resume.`,
      'Experience Tip 1.2': `Give us all the details on the work
      experiences you've had over time. It'll be nice to
      tailor this section to the role(s) you will be applying to
      with this resume. Use numbers to add context and showcase
      some accomplishments.`,

      'Experience Tip 1.3': `E.g. Improved user experience by reducing system downtime
      to 1%.`,
      'Employment Type': `Employment Type`,
      'Select Employment Type': `Select Employment Type`,
      Student: `Student`,
      'Part Time': `Part Time`,
      'Full Time': `Full Time`,
      'Self Employed': `Self Employed`,
      Unemployed: `Unemployed`,
      'Company Name': `Company Name`,
      'Where is this company based?': `Where is this company based?`,
      'When did you start at this role?': `When did you start at this role?`,
      'When did you end this role?': `When did you end this role?`,
      'I currently work here': `I currently work here`,
      'Share any achievements': `Share any achievements and what your role entailed
      here`,
      'Achievement #': `Achievement #`,
      'Add Achievement': `Add Achievement`,
      'Add Experience': `Add Experience`,
      'Headline Tip 1.1': `Tell us a compelling summary of your experiences and interests.`,
      'Headline Tip 1.2': `Keep it short (one to two lines at most). Use the title case.
      Include years of relevant experience (if you're
      experienced). Add one or two keywords from the job ad.`,
      'Headline Tip 1.3': `E.g "Detail-oriented UX Designer with 5+ years of design
      experience working with Balsamiq, Figma and Adobe XD."`,
      'Headline Tip 1.4': `Let's hear a compelling summary of your experiences, achievements and background. This is the first thing recruiters and hiring managers look at when you present your resume.`,

      // Resources Page
      Welcome: 'Welcome to the',
      'Resouce Center': 'Resource Center',
      'Make use of the different':
        'Make use of the different tools provided here to improve your chances getting your desired job.',
      Featured: 'Featured',
      'We are featured':
        'We are featured by other top notch teams to assist in getting the best for you.',
        'Dev': 'Dev',
        'Difference': 'Difference',
        'An AI-enabled interview prep': 'An AI-enabled interview prep platform practice for your next job with us'
    },
  },
  fr: {
    translation: {
      'HR tech startup': `Startup technologique RH axée sur l'embauche sans cruauté.`,
    },
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'en', // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
