import React, { forwardRef, useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { questionnaireRecommendations as recommendations } from '../../../../dummy-data/questionnaire';

const Results = forwardRef(({ answerCount }, ref) => {
  const [highestKeys, setHighestKeys] = useState([]);
  let highestValue = -Infinity;
  const optionsArr = Object.keys(answerCount);

  const getKeysWithHighestValue = () => {
    for (let i = 0; i < optionsArr.length; i++) {
      const currentValue = answerCount[optionsArr[i]];
      if (currentValue > highestValue && currentValue !== 0) {
        setHighestKeys([optionsArr[i]]);
        highestValue = answerCount[optionsArr[i]];
      } else if (currentValue === highestValue && currentValue !== 0) {
        setHighestKeys((prev) => [...prev, optionsArr[i]]);
      }
    }
  };

  useEffect(() => {
    getKeysWithHighestValue();
  }, [answerCount]);

  return (
    <Box
      ref={ref}
      height="100vh"
      width="100vw"
      px={2}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        scrollSnapAlign: 'center',
        textAlign: 'center',
      }}
    >
      <Box maxWidth="720px">
        <Typography fontSize={['1.25rem', '1.5rem']} mb={[2, 4]}>
          {recommendations[highestKeys.join('')]?.recommendation}
        </Typography>
        <Typography fontSize={['1rem', '1.25rem']} sx={{ opacity: '0.7' }}>
          Recommended Jobs: {recommendations[highestKeys.join('')]?.jobs}
        </Typography>
      </Box>
    </Box>
  );
});

Results.displayName = 'Results';
export default Results;
