import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Container, Grid, Box, Input } from '@mui/material';
import Layout from '../../layout/Layout';
import buildLogo from '../../../assets/images/home_icon_build.png';
import searchLogo from '../../../assets/images/home_icon_search.png';
import templateLogo from '../../../assets/images/home_icon_templates.png';

const MainContent = styled.main`
  width: 100%;

  .title {
    font-family: 'Circular Std Bold';
    font-size: 32px;
    line-height: 32px;
    letter-spacing: 0em;
    /* text-align: left; */
    color: #131936;
    margin-bottom: 20px;
  }

  .description {
    font-family: 'Circular Std Bold';
    font-size: 22px;
    line-height: 32px;
    letter-spacing: 0em;
    /* text-align: left; */
    color: #131936;
    margin-bottom: 30px;
  }

  .box-section {
    background: rgba(92, 112, 205, 0.04);
    border: 1px solid #efefef;
    border-radius: 10px;
    margin-bottom: 55px;
    padding: 35px 45px 0;
    height: 372px;
    text-align: center;
    vertical-align: middle;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .upload-file {
    margin-top: 10px;
    padding: 10px 16px;
    border-radius: 4px;
    background: #5c70cd;
    font-size: 16px;
    border: none;
    color: white;
    cursor: pointer;
    display: flex;
    align-items: center;
    & input {
      visibility: hidden;
      width: 0;
      height: 0;
    }
  }
  .box-icon {
    max-width: 70px;
    margin-bottom: 18px;
  }

  .box-description {
    font-family: 'Circular Std Bold';
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 0em;
    color: #131936;
  }

  .box-section:hover {
    border: 1px solid #5c70cd;
    background: rgba(92, 112, 205, 0.13);
    cursor: pointer;
  }
`;

const Home = () => {
  const { t, i18n } = useTranslation();
  const { user } = useSelector((state) => state.auth);

  return (
    <Layout>
      <Helmet>
        <title>Rezzie</title>
      </Helmet>
      <MainContent>
        <Container id="content-section">
          {/* <p className="title">Hi {user.firstName},</p>
          <p className="description">What would you like to do today?</p> */}
          <p className="title">{t('Resume Builder')}</p>
        </Container>
        <Container id="content-section">
          <Grid container spacing={6}>
            <Grid item xs={12} sm={6} md={4}>
              <Link to="/resume/build/contact">
                <Box className="box-section">
                  <img className="box-icon" src={buildLogo} alt="" />
                  <p className="box-description">{t('Build a new resume')}</p>
                </Box>
              </Link>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Link to="/profile">
                <Box className="box-section">
                  <img className="box-icon" src={searchLogo} alt="" />
                  <p className="box-description">{t('View/edit resume')}</p>
                </Box>
              </Link>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Link to="/selecttemplate">
                <Box className="box-section">
                  <img className="box-icon" src={templateLogo} alt="" />
                  <p className="box-description">
                    {t('Check out our resume templates')}
                  </p>
                </Box>
              </Link>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Link to="/resume/upload/upload">
                <Box className="box-section">
                  <img className="box-icon" src={templateLogo} alt="" />
                  <p className="box-description">
                    Upload resume
                  </p>
                </Box>
              </Link>
            </Grid>
          </Grid>
        </Container>
      </MainContent>
    </Layout>
  );
};

export default Home;
