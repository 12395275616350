import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { ResourcesFeaturedIcon } from '../../../../assets';
import BlueButton from '../common-components/BlueButton';

function Featured() {
  return (
    <Box
      as="section"
      sx={{
        padding: { xs: '3rem 1.25rem', md: '4rem 2.5rem' },
        '& .section-img': {
          display: 'block',
          maxWidth: {md: '50%'},
          mb: { xs: '1rem', md: '0' },
        },
      }}
    >
      <Box>
        <Box textAlign="center">
          <Typography
            fontSize={['1.75rem', "2rem"]}
            fontWeight={600}
            mb={[1,3]}
          >
            Featured
          </Typography>
          <Typography maxWidth={['475px']} mx="auto" fontSize="20px">
            Here we feature platforms from other top notch teams to assist you
            in getting your desired job.
          </Typography>
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection={['column-reverse', 'row']}
        alignItems="center"
        gap={[2, 6]}
      >
        <Box textAlign={['center', 'left']} mt={[0, 6]} width={['100%', '50%']}>
          <Typography
            fontSize={['1.5rem']}
            fontWeight={600}
            mb={[1,3]}
          >
            <Box as="span" color="#FF7A00">
              &#123;
            </Box>{' '}
            Dev{' '}
            <Box as="span" color="#FF7A00">
              &#125;
            </Box>{' '}
            Difference
          </Typography>
          <Typography
            fontSize="20px"
            maxWidth={['100%', '450px']}
            mx={['auto', '0']}
          >
            An AI-enabled interview prep platform to help you practice for your
            next job with us
          </Typography>

          <Box mt={[2, 4]}>
            <a
              href="https://thedevdifference.com/"
              target="_blank"
              rel="noreferrer"
            >
              <BlueButton>Try for free</BlueButton>
            </a>
          </Box>
        </Box>
        <img
          className="section-img"
          src={ResourcesFeaturedIcon}
          alt="dev difference"
        />
      </Box>
    </Box>
  );
}

export default Featured;
