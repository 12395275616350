import React, { useState, useEffect } from 'react';
import { useLinkedIn } from 'react-linkedin-login-oauth2';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { Link, useHistory } from 'react-router-dom';
import { Formik } from 'formik';
import { Helmet } from 'react-helmet-async';
import { withTranslation } from 'react-i18next';
// import TwitterLogin from 'react-twitter-login';
import * as yup from 'yup';
import { Grid, Divider, Typography } from '@mui/material';
import utility from '../../../utils/index';
import { googleLogin, linkedinLogin, login } from '../../../redux/actions';
import {
  MainWrapper,
  // CoverDiv,
  // MoreDescription,
  FormWrapper,
  ForgotPasswordText,
  // TwitterButton,
  LinkedInButton,
  MainLoginButton,
} from '../styles';

import linkedinIcon from '../../../assets/images/linkedinicon.svg';
import { hideLoader, showLoader } from '../../../utils/loader';
import GoogleLogin from './googleLogin';
import { envConfig } from '../../../utils/envConfig';
import AuthLayout from '../../../components/AuthLayout';
import Navbar from '../../../components/Navbar';
/* Check if object has no values in it */
const { isMyObjectEmpty } = utility;
const App = ({ location, t }) => {
  const { isLoggingIn, user } = useSelector((state) => state.auth);

  /* Check if user logged in */
  const isLoggedIn =
    !isMyObjectEmpty(user) && localStorage.getItem('rezzie_token');

  const history = useHistory();

  const [initialValues] = useState({
    email: '',
    password: '',
  });
  const [passwordIsText, setPasswordIsText] = useState(false);

  /* View Paaword text Switch */
  const handleSwitchPasswordType = () => setPasswordIsText(!passwordIsText);

  const dispatch = useDispatch();

  const handleAutoLogin = async (code, type) => {
    if (!code) {
      toast.error('Error authenticating, try again!');
      return;
    }
    if (type === 'linkedin') {
      dispatch(linkedinLogin({ code }));
    }

    if (type === 'google') {
      dispatch(googleLogin(code));
    }
  };

  const { linkedInLogin } = useLinkedIn({
    clientId: envConfig.linkedinClientId,
    redirectUri: `${window.location.origin}/linkedin`,
    onSuccess: (code) => {
      showLoader();
      console.log(code);
      handleAutoLogin(code, 'linkedin');
    },
    onError: (error) => {
      console.log(error);
    },
  });

  useEffect(() => {
    let loginToApp;
    if (isLoggedIn)
      loginToApp = setTimeout(() => history.push('/resume'), 1500);
    hideLoader();

    return () => clearTimeout(loginToApp);
  }, [isLoggedIn]);

  useEffect(() => {
    const code = new URLSearchParams(location.search).get('code');
    if (code) {
      showLoader();
      handleAutoLogin(code, 'linkedin');
    } else {
      hideLoader();
    }

    console.log(envConfig.linkedinClientId);
  }, []);

  /* Validation schema for form Inputs */
  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .required('An email is required')
      .email('Please put in a valid email address'),
    password: yup.string().required('Password is required'),
  });
  return (
    <MainWrapper>
      <Helmet>
        <title>Rezzie | Log In</title>
      </Helmet>
      <Navbar />
      <Grid container>
        <AuthLayout />
        <Grid item xs={12} md={6}>
          {/* Formik for handling all form inputs on form submission */}
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values) => dispatch(login(values))}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <FormWrapper>
                <form onSubmit={handleSubmit}>
                  <p className="login-caption">{t('Sign in to Rezzie')}</p>
                  <GoogleLogin
                    handleAutoLogin={handleAutoLogin}
                    text={t('Sign in with Google')}
                  />
                  <LinkedInButton type="button" onClick={linkedInLogin}>
                    <img src={linkedinIcon} alt="google" />
                    &nbsp;&nbsp;&nbsp;&nbsp; {t('Sign in with LinkedIn')}
                  </LinkedInButton>
                  {/* <TwitterLogin
                authCallback={(data) => console.log('Tdata>>>', data)}
                consumerKey="f5hbZ74OhukLvkkfbK7xaoH7O"
                consumerSecret="O0IUSMpFnA90y38QupLEVWA0SB11yXO2U2-HYilnXtBY_ub6XU"
              /> */}
                  {/* 
              <TwitterLogin
                // debug={debug}

                authCallback={(data) => console.log(data)}
                // buttonTheme={buttonTheme}
                // className={customClassName}
                customButton={
                  <TwitterButton type="button">
                    <img src={twitterIcon} alt="twitter" />
                    &nbsp;&nbsp;&nbsp;&nbsp; Log in with Twitter
                  </TwitterButton>
                }
              /> */}

                  <Divider sx={{ py: 2 }}>
                    <Typography
                      variant="subtitle"
                      sx={{ color: 'text.secondary' }}
                    >
                      {t('or')}
                    </Typography>
                  </Divider>

                  <label htmlFor="email">
                    {t('Email Address')}
                    <input
                      type="email"
                      name="email"
                      id="email"
                      placeholder={t('Email Address')}
                      className={
                        errors.email && touched.email && 'rez_input_error'
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      autoComplete="true"
                    />
                  </label>

                  <label htmlFor="password">
                    {t('Password')}
                    <div className="password-input">
                      <input
                        type={passwordIsText ? 'text' : 'password'}
                        name="password"
                        id="password"
                        placeholder="Enter your password"
                        className={
                          errors.password &&
                          touched.password &&
                          'rez_input_error'
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.password}
                        autoComplete="true"
                      />
                      <Eye
                        handleSwitchPasswordType={handleSwitchPasswordType}
                      />
                    </div>
                  </label>
                  <MainLoginButton type="submit" disabled={isLoggingIn}>
                    {isLoggingIn ? t('Please wait...') : t('Log in')}
                  </MainLoginButton>
                  <ForgotPasswordText>
                    <Link to="/forgotpassword">
                      {' '}
                      {t('Forgot your password?')}
                    </Link>
                    &nbsp;or&nbsp;
                    <Link to="/resumeandcoverletterbuilder/signup">
                      Sign up
                    </Link>
                  </ForgotPasswordText>
                </form>
              </FormWrapper>
            )}
          </Formik>
        </Grid>
      </Grid>
    </MainWrapper>
  );
};

export default withTranslation()(App);

// const Liner = () => (
//   <svg
//     width="185"
//     height="2"
//     viewBox="0 0 185 2"
//     fill="none"
//     xmlns="http://www.w3.org/2000/svg"
//   >
//     <line y1="1" x2="185" y2="1" stroke="#E7E7ED" strokeWidth="2" />
//   </svg>
// );

const Eye = ({ handleSwitchPasswordType }) => (
  <svg
    width="16"
    height="10"
    viewBox="0 0 16 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    onClick={handleSwitchPasswordType}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.15345 0C9.10755 0 12.8937 0.184326 15.6163 4.13123L15.6613 4.19434C15.8566 4.46826 16.1593 4.89368 15.8976 5.35938C13.465 9.67188 9.50306 10 8.4054 10H7.94056C6.97376 10 3.14368 9.81567 0.418099 5.86877L0.363411 5.79053C0.169075 5.51624 -0.126823 5.0979 0.130989 4.64062C2.56751 0.328125 6.58118 0 7.69154 0H8.15345ZM6.33509 5.20312C6.33509 6.08435 7.04603 6.79688 7.92591 6.79688C8.80482 6.79688 9.51673 6.08435 9.51673 5.20312C9.51673 4.3219 8.80482 3.60938 7.92591 3.60938C7.04603 3.60938 6.33509 4.3219 6.33509 5.20312ZM1.77845 5.0188C2.61439 3.70312 3.71204 2.74695 5.04993 2.17505C5.25599 2.08752 5.45911 2.01257 5.6554 1.9469C4.63197 2.66565 3.96204 3.85632 3.96204 5.20312C3.96204 6.13123 4.27943 6.98438 4.81361 7.66248C3.79603 7.16882 2.714 6.35315 1.77845 5.0188ZM11.8927 5.20312C11.8927 6.25317 11.4845 7.2063 10.8234 7.91565C11.9493 7.46875 13.2345 6.61255 14.2667 4.9812C12.9601 3.1156 11.3937 2.2688 10.1183 1.89062C11.1876 2.60315 11.8927 3.8219 11.8927 5.20312Z"
      fill="#6B6C7E"
    />
  </svg>
);
