import React from 'react';
import { Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/logo.png';

function Footer() {
  return (
    <Box
      as="footer"
      sx={{
        display: 'grid',
        gridTemplateColumns: { md: '40% 60%' },
        '& .logo': {
          width: { xs: '80px', md: '300px' },
          ml: 3,
        },
      }}
    >
      <Box>
        <img className="logo" src={logo} alt="rezzie logo" />
      </Box>
      <Box>
        <Box
          sx={{
            mb: 4,
            pl: 3,
            display: 'flex',
            gap: 6,
            '& .footer-links': {
              display: 'block',
              color: '#707070',
              mb: 1.5,
            },
            '& h5': {
              fontWeight: 600,
              mb: 1.5,
            },
          }}
        >
          <Box>
            <Typography as="h5"> Helpful Links</Typography>
            <Link className="footer-links" to="/">
              Home
            </Link>
            <a
              className="footer-links"
              href="https://rezzieco.wordpress.com"
              target="_blank"
              rel="noreferrer"
            >
              Blog
            </a>
          </Box>
          <Box>
            <Typography as="h5"> Social</Typography>
            <a
              className="footer-links"
              href="https://www.linkedin.com/company/rezzie/"
              target="_blank"
              rel="noreferrer"
            >
              LinkedIn
            </a>
            <a
              className="footer-links"
              href="https://twitter.com/rezzieco"
              target="_blank"
              rel="noreferrer"
            >
              Twitter
            </a>
            <a
              className="footer-links"
              href="https://instagram.com/the.rezzie?igshid=ZDg1NjBiNjg="
              target="_blank"
              rel="noreferrer"
            >
              Instagram
            </a>
          </Box>
        </Box>{' '}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            fontSize: '0.75rem',
            borderTop: '1px solid #c1c1c1',
            height: '3rem',
          }}
        >
          <Box as="span">@ 2021 Rezzie. All rights reserved.</Box>
          <Box
            sx={{
              marginLeft: '15px',
              '& a': {
                display: 'inline-block',
                color: '#707070',
              },
            }}
          >
            <Link to="/privacypolicy" target="_blank">
              Privacy Policy
            </Link>{' '}
            |{' '}
            <Link to="/termsandconditions" target="_blank">
              Terms of Use
            </Link>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default Footer;
