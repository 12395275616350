export const questionnaire = [
  {
    id: 0,
    question: 'How would you describe your personality in social situations?',
    options: [
      {
        id: 'A',
        text: 'Outgoing and Sociable',
      },
      {
        id: 'B',
        text: 'Reserved and Observant',
      },
      {
        id: 'C',
        text: 'Balanced, depending on the context',
      },
    ],
  },
  {
    id: 1,
    question: 'When facing challenges, how do you typically respond?',
    options: [
      {
        id: 'A',
        text: 'Embrace challenges and take risks',
      },
      {
        id: 'B',
        text: 'Analyze the situation and find a strategic solution',
      },
      {
        id: 'C',
        text: 'Seek guidance from others and collaborate',
      },
    ],
  },
  {
    id: 2,
    question: 'What best describes your approach to teamwork?',
    options: [
      {
        id: 'A',
        text: 'Enjoy leading and taking charge',
      },
      {
        id: 'B',
        text: 'Prefer working independently',
      },
      {
        id: 'C',
        text: 'Collaborate well with others',
      },
    ],
  },
  {
    id: 3,
    question: 'Which area do you feel most skilled in?',
    options: [
      {
        id: 'A',
        text: 'Analytical skills (data analysis, problem-solving)',
      },
      {
        id: 'B',
        text: 'Technical skills (programming, IT)',
      },
      {
        id: 'C',
        text: 'Communication skills (writing, public speaking)',
      },
    ],
  },
  {
    id: 4,
    question: 'What tools or software are you most comfortable using?',
    options: [
      {
        id: 'A',
        text: 'Spreadsheet software (Excel, Google Sheets)',
      },
      {
        id: 'B',
        text: 'Coding languages (Python, Java)',
      },
      {
        id: 'C',
        text: 'Presentation software (PowerPoint, Keynote)',
      },
    ],
  },
  {
    id: 5,
    question: 'How would you describe your communication style?',
    options: [
      {
        id: 'A',
        text: 'Assertive and direct',
      },
      {
        id: 'B',
        text: 'Diplomatic and empathetic',
      },
      {
        id: 'C',
        text: 'Adaptive to different communication styles',
      },
    ],
  },
  {
    id: 6,
    question: 'When working on a project, what role do you usually play?',
    options: [
      {
        id: 'A',
        text: 'Leader/Coordinator',
      },
      {
        id: 'B',
        text: 'Individual Contributor',
      },
      {
        id: 'C',
        text: 'Team Player/Collaborator',
      },
    ],
  },
  {
    id: 7,
    question: 'What work environment do you find most productive?',
    options: [
      {
        id: 'A',
        text: 'Dynamic and fast-paced',
      },
      {
        id: 'B',
        text: 'Quiet and focused',
      },
      {
        id: 'C',
        text: 'Flexible and adaptable',
      },
    ],
  },
  {
    id: 8,
    question: 'How do you manage your time and prioritize tasks?',
    options: [
      {
        id: 'A',
        text: 'Set strict schedules and deadlines',
      },
      {
        id: 'B',
        text: 'Prioritize based on urgency and importance',
      },
      {
        id: 'C',
        text: 'Adapt to changing priorities as needed',
      },
    ],
  },
];

export const questionnaireRecommendations = {
  'ABC': {
    recommendation:
      'Based on your answers, you may have the flexibility to explore a wide range of careers. You may find roles that involve a combination of leadership, analytical thinking, and collaboration could be particularly appealing. You should consider suggesting positions that offer a mix of responsibilities, allowing them to utilize their diverse skill set.',
    jobs: 'Digital Marketing Specialist, Product Manager, Sales and Customer Success Representative',
  },
  A: {
    recommendation:
      'Based on your answers, you may excel in roles that allow you to leverage your outgoing and sociable nature, leadership skills, and comfort in taking risks. You may find fulfillment in dynamic environments where you can interact with others, lead initiatives, and navigate challenges with confidence.',
    jobs: 'Recommended Jobs: Sales Manager, Event Coordinator, Public Relations Manager, Marketing Director.',
  },
  B: {
    recommendation:
      'Based on your answers, you may excel in roles that allow you to leverage your analytical and independent nature. You may find fulfillment in roles where they can work on complex problems, analyze data, and contribute to projects that require a strategic mindset and attention to detail.',
    jobs: 'Software Developer, Data Scientist, Research Scientist, Quality Assurance Analyst',
  },
  C: {
    recommendation:
      'Based on your answers, you may excel in roles that require adaptability, collaboration, and effective communication across various contexts. You may find fulfillment in careers that involve working with diverse teams, understanding different perspectives, and contributing to projects that benefit from a balanced and flexible approach.',
    jobs: 'Marketing Specialist, Human Resources Coordinator, Business Analyst, Customer Success Manager',
  },
  'AB': {
    recommendation: 'Based on your answers, you may excel in roles that allow you to leverage your outgoing and sociable nature as well as your analytical and independent nature. You may find fulfillment in careers that involve where you can wear multiple hats, contributing to projects that require both strategic thinking and effective interpersonal skills.',
    jobs: 'Marketing Analyst, Sales Analyst, Project Coordinator, Customer Experience Specialist'
  },
  'AC': {
    recommendation: 'Based on your answers, you may excel in roles that allow you to leverage your outgoing and sociable nature as well as your adaptive and collaborative qualities. You may find fulfillment in careers that offer a harmonious mix of responsibilities, specifically roles that involve effective communication, leadership, and collaboration across diverse teams could be particularly appealing.',
    jobs: 'Community Engagement Specialist, Marketing Coordinator, Human Resources Generalist, Project Manager'
  },
  'BC': {
    recommendation: 'Based on your answers, you may excel in roles that allow you to leverage analytical and independent nature as well as your adaptive and collaborative qualities. You may find fulfillment in careers that offer a blend of responsibilities requiring analytical thinking, problem-solving, and effective communication.specifically roles that involve independent work on complex challenges while also engaging with diverse teams could be particularly fulfilling.',
    jobs: 'Operation Manager, UI/UX Designer, Data Analyst, Financial Advisor'
  }
};
